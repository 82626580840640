import axios from 'axios'

import {
  SIGN_IN, SIGN_OUT, SIGN_UP, PASSWORD_REQUEST_TOKEN,
  PASSWORD_RESET,  CHECK_USER
} from '@/store/types/actions';

import {
  SET_USER, CLEAR, UPDATE_USER
} from '@/store/types/mutations';

import {
  SIGN_IN_ENDPOINT, SIGN_UP_ENDPOINT, PASSWORD_REQUEST_TOKEN_ENDPOINT,
  PASSWORD_RESET_ENDPOINT,USER_ENDPOINT,
} from '@/utils/Routes';

export default {
  namespaced: true,

  state: {
    user: null,
    token: null,
  },

  mutations: {
    [SET_USER] (state, payload) {
      // console.log(payload)
      state.user = payload.user;
      state.token = payload.token;

      localStorage.setItem('user', JSON.stringify(payload.user));
      localStorage.setItem('token', JSON.stringify(payload.token));

      axios.defaults.headers.common.Authorization = `Bearer ${payload.token}`;
    },

    [UPDATE_USER] (state, payload) {
      state.user = payload.user;
      localStorage.setItem('user', JSON.stringify(payload.user));
    },

    [CLEAR] (state) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');

      state.user = null;
      state.token = null;
    },
  },

  actions: {
    async [SIGN_IN] ({ commit }, credentials) {
        let response = await axios.post(SIGN_IN_ENDPOINT, credentials);

        if(response.data.success === true) {
          commit(SET_USER, response.data)
          return Promise.resolve(true);
        } else {
          return Promise.reject(response.data.errors);
        }
    },

    /* ------------------------------------------------------------------------------------ */

    async [SIGN_UP] ({ state, commit }) {
      let response = await axios.post(SIGN_UP_ENDPOINT, state.form);

      if(response.data.success === true) {
        commit(SET_USER, response.data);
        return Promise.resolve(true)
      } else {
        return Promise.reject(response.data.errors)
      }
    },

    /* ------------------------------------------------------------------------------------ */

    async [PASSWORD_REQUEST_TOKEN] (store, payload) {
      let response = await axios.post(PASSWORD_REQUEST_TOKEN_ENDPOINT, payload);

      if(response.data.success === false) {
        throw { errors: response.data.errors };
      }
    },

    /* ------------------------------------------------------------------------------------ */

    async [PASSWORD_RESET] (store, payload) {
      let response = await axios.post(PASSWORD_RESET_ENDPOINT, payload);

      if(response.data.success === false) {
        throw { errors: response.data.errors };
      }
    },

    /* ------------------------------------------------------------------------------------ */

    async [SIGN_OUT] ({ commit }) {
      await new Promise((resolve) => {
        setTimeout(() => {
          commit(CLEAR);
          resolve();
        }, 1000);
      })
    },

    /* ------------------------------------------------------------------------------------ */

    async [CHECK_USER] ({ commit }) {
      let { data } = await axios.get(USER_ENDPOINT);

      if(data.success === true) {
        commit(UPDATE_USER, data)
      } else {
        throw { errors: data.errors };
      }
    },
  },
}
