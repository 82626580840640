export default (to, from, next) => {

  const loggedIn = localStorage.getItem('token');

  if (!loggedIn) {
    next('/auth/login');
  }

  next();
}
