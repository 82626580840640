<template>
  <div id="app" class="min-h-screen">
    <transition name="simple-fade" mode="out-in">
      <router-view />
    </transition>
    <portal-target name="modals" slim />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
