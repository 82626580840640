import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    organizations: [],
    organization: null,
    
    events: [],
    event: null,
    eventsCount: 0,
  },
  mutations: {
    setOrganizations(state, data) {
      state.organizations = data
    },

    setOrganization(state, data) {
      state.organization = data
    },

    clearOrganization(state) {
      state.organization = null
    },

    setEvents(state, data) {
      state.events = data
    },

    setEvent(state, data) {
      state.event = data
    },

    clearEvent(state) {
      state.event = null
    },
  },
  actions: {
    fetchOrganizations({ commit }) {
      axios.get(`/webapp/organizations`)
        .then(({ data }) => {
          commit('setOrganizations', data.organizations)

          return Promise.resolve(data.organizations)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchEvents({ commit }) {
      return axios.get(`/webapp/events`)
        .then(({ data }) => {
          commit('setEvents', data.events)

          return Promise.resolve(data.events)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchOrganization({ commit }, organizationId) {
      axios.get(`/webapp/organizations/${organizationId}`)
        .then(({ data }) => {
          commit('setOrganization', data.organization)

          return Promise.resolve(data.organization)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchEvent({ commit }, eventId) {
      return axios.get(`/webapp/events/${eventId}`)
        .then(({ data }) => {
          commit('setEvent', data.event)

          return Promise.resolve(data.event)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchEventsCount() {
      console.log('fetching events count')
    }
  },
  modules: {
    auth,
  }
})
