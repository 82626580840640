
// Global actions
export const GET = 'GET';
export const SHOW = 'SHOW';
export const STORE = 'STORE';
export const UPDATE = 'UPDATE';

// Auth actions
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_UP = 'SIGN_UP';
export const PASSWORD_REQUEST_TOKEN = 'PASSWORD_REQUEST_TOKEN';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const CHECK_USER = 'CHECK_USER';
export const UPDATE_AREAS = 'UPDATE_AREAS';
