import Vue from 'vue';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import "./assets/css/app.css"
import { ValidationProvider, ValidationObserver,extend } from 'vee-validate';
import { mapMutations, mapActions } from 'vuex';
import { CHECK_USER, SIGN_OUT } from '@/store/types/actions';
import { SET_USER } from '@/store/types/mutations';
import { BASE_API_URL } from '../env';
import { required,confirmed ,email} from "vee-validate/dist/rules";
import PortalVue from 'portal-vue'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import dayjs from 'dayjs'

Vue.use(PortalVue)

Vue.use(VueToast, {
  position: 'bottom-right',
  duration: 5000,
});

/** VEE VALIDATE */
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
axios.defaults.baseURL = BASE_API_URL;
Vue.config.productionTip = false
Vue.prototype.$dayjs = dayjs

extend('confirmed', confirmed);
extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Password confirmation does not match",
});
extend("email", email);

extend("required", {
  ...required,
  message: "This field is required",
});

extend('min', value => {
  return value.length >= 3;
});

/*--------------------*/
new Vue({
  router,
  store,
  created: async function() {
    try {
      //const user = localStorage.getItem('user');
      const token = localStorage.getItem('token');

      if(token) {
        this.setUser({
          user: JSON.parse(null),
          token: JSON.parse(token),
        });

        await this.checkUser();
      }
    } catch (ex) {
      this.signOut();
      this.$router.push('/auth/login');
    }

    axios.interceptors.response.use(response => response, error => {
      if (error.response.status === 401) {
        this.signOut();
        this.$router.push('/auth/login');
      }
      if (error.response.status === 403) {
        this.$router.replace('/403');
      }
      return Promise.reject(error)
    });
  },


  methods: {
    ...mapMutations({
      setUser: `auth/${SET_USER}`,
    }),

    ...mapActions({
      checkUser: `auth/${CHECK_USER}`,
      signOut: `auth/${SIGN_OUT}`
    }),
  },
  render: h => h(App)
}).$mount('#app')
