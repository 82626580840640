import Vue from 'vue'
import VueRouter from 'vue-router'
import authMiddleware from '@/middlewares/authMiddleware'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',

  routes: [
    {
      path: '/',
      name: 'main',
      beforeEnter: authMiddleware,
      component: () => import(/* webpackChunkName: "main" */ '@/layouts/MainLayout'),
      children: [
        {
          path: '',
          name: 'home',
          redirect: '/events',
          beforeEnter: authMiddleware
        },
        {
          path: 'events',
          name: 'events.index',
          component: () => import(/* webpackChunkName: "main" */ '@/views/events/Index'),
          beforeEnter: authMiddleware
        },
        {
          path: 'events/:eventId',
          name: 'events.show',
          component: () => import(/* webpackChunkName: "main" */ '@/views/events/Show'),
          beforeEnter: authMiddleware
        },
        {
          path: 'organizations',
          name: 'organizations.index',
          component: () => import(/* webpackChunkName: "main" */ '@/views/organizations/Index'),
          beforeEnter: authMiddleware,
        },
      
        {
          path: 'organizations/:organizationId',
          name: 'organizations.show',
          component: () => import(/* webpackChunkName: "main" */ '@/views/organizations/Show'),
          beforeEnter: authMiddleware,
        },
        {
          path: '403',
          name: 'Forbidden',
          component: () => import(/* webpackChunkName: "main" */ '@/views/errors/Forbidden'),
          beforeEnter: authMiddleware,
        },
        {
          path: '404',
          name: 'Not Found',
          component: () => import(/* webpackChunkName: "main" */ '@/views/errors/NotFound'),
          beforeEnter: authMiddleware,
        },
      ]
    },
    {
      path: '/auth',
      name: 'auth',
      component: () => import(/* webpackChunkName: "auth" */ '@/layouts/AuthLayout'),
      children: [
        {
          path: 'login',
          name: 'signIn',
          component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Signin')
        },
        {
          path: 'forgot-password',
          name: 'ForgotPassword',
          component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ForgotPassword')
        },
        {
          path: 'reset-password',
          name: 'ResetPassword',
          component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ResetPassword')
        },
      ]
    },
    {
      path: '*',
      redirect: '/404',
      beforeEnter: authMiddleware
    },
  ]
});